import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client';
import $ from 'jquery'
import 'webpack-jquery-ui/datepicker'
import './polyfills'
import './email-subscribe'
import '/lib/tab'
import Carousel from './components/carousel'
import Quote_Carousel from './components/quote-carousel'
import Testimonial_Carousel from './components/testimonial-carousel'
import Images_Carousel from './components/images-carousel'
import MobileDetect from 'mobile-detect'

import addAnchorTransition from '/functions/add-anchor-transition'

addAnchorTransition()

$('body').bind('touchmove', function(e){
  if( $('#mobile-nav-toggle').is(':checked') ){
    e.preventDefault()
  }
})

$(document).ready( () => {
  if($('.blog--main').length > 0) {
    import('swiper').then( obj => {
      const Swiper = obj.default;

      const swiper = new Swiper('.swiper-container', {
        // Optional parameters
        direction: 'horizontal',

        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        watchSlidesVisibility: true,
        slidesPerView : 1,
        observer: true,
        observeParents: true,
        autoHeight: true,
        updateOnWindowResize : true,

      });
    }).catch(err => { console.log(err); console.log('Error importing swiper module')});

  }
});

$(document).ready( ()=>{
  let toggle = $('.mobile-nav .menu-item-has-children:not(.js-clickable) > a')
  toggle.click( function( event ){
    event.preventDefault()
    let sub = $(this).siblings('.sub-menu')
    sub.slideToggle()
  })

  $('.main-nav .menu-item-has-children:not(.js-clickable) > a').click(
    function( event ){ event.preventDefault() }
  )
})

$(document).ready( function(){
  let carousel = $('.page-header__carousel' )
  if( carousel.length ){
    let slides = carousel.data( 'slides' )
    const root = createRoot( carousel.get()[0] )
    root.render(<Carousel slides={ slides } />)
  }
})

$(document).ready( function(){
  let quote_carousel = $('.js-quote-carousel' )
  if( quote_carousel.length ){
    let quote_slides = quote_carousel.data( 'slides' )
    ReactDOM.render(
      <Quote_Carousel slides={ quote_slides } />,
      quote_carousel.get()[0]
    )
  }
})

$(document).ready( function(){
  let testimonial_carousel = $('.js-testimonial-carousel' )
  if( testimonial_carousel.length ){
    let testimonial_slides = testimonial_carousel.data( 'slides' )
    const root = createRoot( testimonial_carousel.get()[0] )
    root.render( <Testimonial_Carousel slides={ testimonial_slides } /> )
  }
})

$(document).ready( function(){
  let $images_carousels = $('.js-images-carousel' )

  if( $images_carousels.length ){

    $images_carousels.each(function() {
      const $carousel = $(this);
      let images_slides = $carousel.data( 'slides' )
      ReactDOM.render(
        <Images_Carousel slides={ images_slides } />,
        $carousel.get()[0]
      )

    })

  }
})

$(document).ready(function($) {
  $('.js-faq').find('.faq__question').click(function(){
    var el = $(this);
    el.next().slideToggle('fast');
    el.toggleClass('active');
    $(".faq__answer").not(el.next()).slideUp('fast');
    $(".faq__question").not(el).removeClass('active');
  });
});

$(document).ready(function(){
  $('.js-show-animation').hover( function(){
    $(this).parent().addClass("main-nav__menu--animating");
  },
  function(){
    $(this).parent().removeClass("main-nav__menu--animating");
  });
});

$(document).ready(function(){
  $(document).on('gform_confirmation_loaded', function(event, formId){
    if(formId == 34) {
      if ($(window).width() < 768) {
        $('html, body').animate({
          scrollTop: $('.cubert-block--content-form .catering-section--form').offset().top
        }, 500);
      }
      else {
        $('html, body').animate({
          scrollTop: $('.cubert-block--content-form').offset().top
        }, 500);
      }
    }
  });
})

$(document).on('gform_confirmation_loaded', function(event, formId){
  if(formId == 5) {
    $('.footer__subscribe .footer__title').hide();
    $('.popup-title').hide();
    $('.popup-intro').hide();
  }
});

$('#sac-sign-up-button').click(function(){
  $('html, body').animate({ scrollTop: "0px" }, 500);
  var signUpInput = $('#input_6_1');
  if (signUpInput) signUpInput.click();
});

// Content Module Tabs & Toggles

$(document).ready(function($) {
  $('.js-tab-link').click(function(e){
    var el = $(this);
    e.preventDefault();

    el.parent().parent().children().removeClass('active');
    el.parent().addClass('active');

    var id = el.attr('href');

    el.parent().parent().siblings().removeClass('active');
    el.parent().parent().siblings(id).addClass('active');
  });
  $('.js-toggle-link').click(function(e){
    var el = $(this);
    e.preventDefault();

    el.parent().parent().find('.js-toggle-link').removeClass('active');
    el.addClass('active');

    var id = el.attr('href');

    el.parent().parent().siblings().find('img').removeClass('active');
    el.parent().parent().siblings().find(id).addClass('active');
  });
});

//This method is called after php injects the view vendors button into the dom for some events
window.setViewVendorsListener = function(){
  $('.view-vendors-button').click(function(){
    var vendorsOffset = $('.vendors').offset().top;
    $('html, body').animate({ scrollTop: vendorsOffset }, 500);
  });
};

// Scroll to vendors on event page
$(window).on('eventsIndexLoadingComplete', function(event) {
  $('.view-vendors-button').css('opacity', 1);
  $('.view-vendors-button').click(function(){
    var vendorsOffset = $('.wrap-maincontent').offset().top;
    $('html, body').animate({ scrollTop: vendorsOffset }, 500);
  });
});

//Initialize for any existing view vendors buttons


window.setViewVendorsListener();

$(document).ready(function(){
  if ( $('.page-template-page-pod').length ) {
    const md = new MobileDetect( navigator.userAgent );
    const $iframe = $('iframe');

    $iframe.each(function() {

      const mobileHeight = $(this).data('frameheight_mobile') ? $(this).data('frameheight_mobile') : this.height
      const deskHeight = $(this).data('frameheight') ? $(this).data('frameheight') : this.height
      const height = md.mobile() ? mobileHeight : deskHeight;
      this.height = height
    })
  }
})

// Detect Mobile Device Vendor
$(function() {
  const versionInfo = navigator.appVersion;

  if (versionInfo.includes('iPhone') || versionInfo.includes('iPad') ) {
    $('html').addClass('device-ios');
  }
})
