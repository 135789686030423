import $ from 'jquery'

export default function addAnchorTransition(){
  $(document).on( 'click', 'a[href^="#"]', function(e){
    e.preventDefault()
    const $target = $(e.target)
    const $mobileNav = $('.mobile-nav')
    const selector = $target.attr('href')
    let offset = $(selector).offset().top
    if( $mobileNav.css('display') === 'flex' ){
      offset -= $mobileNav.outerHeight( true )
    }
    $('html, body').animate({ scrollTop: offset }, 500);
  })
}
