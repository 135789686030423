import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from 'swiper'
import { SCREENXSMAX } from '/js/variables/breakpoints'

SwiperCore.use([Navigation, Pagination]);
import { css } from 'glamor'

const XSMaxQuery = `@media( max-width: ${SCREENXSMAX}px )`
const prevButton = { [XSMaxQuery]: { left: 5 } }
const nextButton = { [XSMaxQuery]: { right: 5 } }

const slideOptions = {
  slidesPerView: 2,
  loop: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  renderPrevButton: () => <button className={`swiper-button-prev ${ css( prevButton ) }`}></button>,
  renderNextButton: () => <button className={`swiper-button-next ${ css( nextButton ) }`}></button>,
  rebuildOnUpdate: true,
  breakpoints: {
    767: {
      slidesPerView: 1,
    },
  }
}
const slideStyles = {
  position: 'relative',
  height: 'auto',
  width: 'auto',
  padding: '0 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const View = ({ slides, layout }) => (
  <Swiper { ...slideOptions } >
    {
      slides.map( ( slide, index ) => (
        <SwiperSlide key={ index } >
          <div className={ css( slideStyles ) } dangerouslySetInnerHTML={ slide } />
        </SwiperSlide>
      ))
    }
  </Swiper>
)


export default View
