import $ from 'jquery'
$(document).ready(function(){
if( 'object-fit' in document.body.style == false ){
  let images = $('.object-fit-polyfill')
  images.each( function(){
    let replacement = $('<div>')
    replacement.addClass( this.className )
    replacement.css( 'background-image', 'url(' + this.getAttribute('data-src') + ')' )
    $(this).replaceWith( replacement )
  })
}
})
