import React from 'react'
import { SCREENSMMIN } from '/js/variables/breakpoints'
import View from './view'

class Testimonial_Carousel extends React.Component {
  render(){
    return(
      <View { ...this.props } />
    )
  }
}

export default Testimonial_Carousel
